(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

require('./vendors/h5bp');

/* 
 * Mapa de CONTACTO  mediante la API de Google Maps
 */

function contactMap() {

  // Ubicación LAT
  var lugar = new google.maps.LatLng(-31.738362, -60.534587);
  var centro = new google.maps.LatLng(-31.734173, -60.534856);

  // Estilos
  var estilos = [{ "elementType": 'labels.text.fill', "stylers": [{ "color": '#000000' }] }, { "featureType": "road", "elementType": "geometry", "stylers": [{ "color": "#e6e6e6" }] }, { "featureType": "landscape", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#7bc4e5" }] }, { "featureType": "water", "stylers": [{ "color": "#7bc4e5" }] }];

  // Opciones del mapa
  var mapOptions = {
    center: centro,
    zoom: 15,
    styles: estilos,
    scrollwheel: false
  };

  // Crear mapa
  var map = new google.maps.Map(document.getElementById("map-canvas"), mapOptions);

  // Marcador
  var marker = new google.maps.Marker({
    position: lugar,
    icon: "../assets/media/location-pin.png",
    map: map, //lo asocio al mapa
    optimized: false,
    title: 'Cetiug Psicología'
  });

  var infoWindow = new google.maps.InfoWindow({
    content: "<p style='text-align:center; color:#959595;'>Pellegrini 604 (Esq Sebastián Vazquez) <br> Parana / Entre Rios / Argentina</p>"
  });

  google.maps.event.addListener(marker, 'click', function () {
    infoWindow.open(map, marker);
  });
}

/* 
 * Envío del formulario
 *
 * - Ejecucion en: $(document).ready()
 */

function formsGo() {

  $("#contact-submit").on('click', function (e) {
    //formulario de contacto
    e.preventDefault();

    var okflag = true;
    $('.contact-page input, .contact-page textarea').filter('[required]:visible').each(function (i, requiredField) {
      if ($(requiredField).val() == '') {
        if (okflag == true) $('.contact-page .msg-all').fadeIn().delay(5000).fadeOut();
        okflag = false;
        $(requiredField).addClass("contact-error");
      } else $(requiredField).removeClass("contact-error");
    });

    if (okflag == true) $('.contact-page .msg').addClass("backGload").fadeIn().load('../contacto/contacto.php', {
      nombre: $('#contact-name').val(),
      email: $('#contact-email').val(),
      telefono: $('#contact-phone').val(),
      // ocupacion: $('#contact-occupation').val(),
      mensaje: $('#contact-message').val()
    }, function () {
      $('.contact-page input, .contact-page textarea').val("");
      $('.contact-page .msg').removeClass("backGload");
    });
  });

  $("#turn-submit").on('click', function (e) {
    //formulario de contacto
    e.preventDefault();

    var okflag = true;
    $('#turn-schedule input, #turn-schedule textarea').filter('[required]:visible').each(function (i, requiredField) {
      if ($(requiredField).val() == '') {
        if (okflag == true) $('#turn-schedule .msg-all').fadeIn().delay(5000).fadeOut();
        okflag = false;
        $(requiredField).addClass("contact-error");
      } else $(requiredField).removeClass("contact-error");
    });

    if (okflag == true) $('#turn-schedule .msg').addClass("backGload").fadeIn().load('../inicio/turno.php', {
      nombre: $('#turn-name').val(),
      fecha: $('#turn-date').val(),
      telefono: $('#turn-phone').val(),
      // ocupacion: $('#contact-occupation').val(),
      mensaje: $('#turn-message').val()
    }, function () {
      $('#turn-schedule input, #turn-schedule textarea').val("");
      $('#turn-schedule .msg').removeClass("backGload");
    });
  });
}

/* 
 * Ejectuo las funciones de materialize
 *
 * - Ejecucion en: $(document).ready()
 */
function goMaterialize() {
  $(".button-collapse").sideNav();
  $('.home-slider').slider({ full_width: true, height: 520 });
  $('.parallax').parallax();
  $('.datepicker').pickadate({
    monthsFull: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    weekdaysFull: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
    firstDay: true,
    selectMonths: true, // Creates a dropdown to control month
    selectYears: 15, // Creates a dropdown of 15 years to control year,
    today: 'Hoy',
    clear: 'Limpiar',
    close: 'Ok',
    closeOnSelect: false // Close upon selecting a date,
  });
}

/* 
* Instanciamos las funciones :D
*
*/
$(document).ready(function () {
  "use strict";

  goMaterialize();
  formsGo();
  if ($("#map-canvas").length) contactMap();
});

},{"./vendors/h5bp":2}],2:[function(require,module,exports){
'use strict';

// Avoid `console` errors in browsers that lack a console.
(function () {
  'use strict';

  var method;
  var noop = function noop() {};
  var methods = ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'];
  var length = methods.length;
  var console = window.console = window.console || {};

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
})();

},{}]},{},[1])

